:root {
  --color-texts: #111;
  --color-hero-bg: #364562;
  --color-bg-navbar: #ffffff65;
  --color-gradient-line-left: #364764;
  --color-gradient-line-right: #77bdff;
  --color-gradient-section-top: #f5f5f5;
  --color-gradient-section-bottom: #c5cedf;
  --color-footer-text: #6b6b6b;
  --color-bg-input: #dbdbdb;
  --color-primary: #f32a4b;
  --color-secondary: #364764;
  --color-white: #fff;
  --color-gray: #dbdbdb;
  --color-black: #010101;
  --color-btn-gray: #a5a5a5;
}

*, :before, :after {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

.hidden__scroll {
  overflow: hidden;
}

.color__primary {
  color: var(--color-primary);
}

.color__secondary {
  color: var(--color-secondary);
}

.preloader {
  z-index: -99999;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation-play-state: pause;
  justify-content: center;
  align-items: center;
  transition: all .38s;
  animation: 30s linear infinite alternate background-change;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.preloader.active {
  opacity: 1;
  z-index: 99999;
  animation-play-state: running;
}

.preloader.active .circles .logo, .preloader.active .circles .pulse {
  animation-play-state: running;
}

.preloader .circles {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.preloader .circles .logo {
  background-color: var(--color-white);
  z-index: 10;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 25px;
  animation: 2s linear infinite alternate paused zoom-in-out;
  display: flex;
  box-shadow: 0 0 0 15px #ffffff15;
}

.preloader .circles .logo img {
  width: 100%;
}

.preloader .circles .pulse {
  background-color: #e2e1e1;
  border-radius: 50%;
  animation: 1.75s ease-in-out infinite backwards paused pulse;
  position: absolute;
}

.preloader.active .circles .pulse.circle__1 {
  opacity: .9;
  animation-delay: 0;
  z-index: 1;
}

.preloader.active .circles .pulse.circle__2 {
  opacity: .75;
  z-index: 2;
  animation-delay: .25s;
}

.preloader.active .circles .pulse.circle__3 {
  opacity: .65;
  z-index: 3;
  animation-delay: .5s;
}

.btn {
  cursor: pointer;
  width: 300px;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border: none;
  border-radius: 25px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  text-decoration: none;
  transition: background .38s, color .38s;
  display: flex;
}

.side__navbar {
  width: 100%;
  height: 100%;
  z-index: -9998;
  visibility: hidden;
  opacity: 0;
  background: linear-gradient(150deg, #5f6880, #0d1928);
  flex-flow: column;
  transition: all .38s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.side__navbar.open {
  visibility: visible;
  opacity: 1;
  z-index: 9998;
  transform: translate3d(0, 0, 0);
}

.side__navbar .header {
  border-bottom: 5px solid var(--color-primary);
  height: 200px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  position: relative;
}

.side__navbar.open .header .blur {
  width: 100%;
  height: 100%;
  filter: blur(5px);
  z-index: -1;
  background-image: linear-gradient(#364562cc, #6b00005e), url("bg-bridge-blue.afa57266.png");
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.side__navbar .header .brand {
  width: 200px;
  height: auto;
  margin-top: 25px;
}

.side__navbar.open .header .brand {
  animation: .75s ease-in-out .15s alternate-reverse both toUp;
}

.side__navbar ul {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  padding: 10px 5px;
  list-style: none;
  display: flex;
  overflow-y: auto;
}

.side__navbar.open ul a li {
  animation: .5s linear .35s alternate-reverse backwards toUp;
}

.side__navbar.open ul a li:nth-of-type(1) {
  animation-duration: .4s;
}

.side__navbar.open ul a li:nth-of-type(2) {
  animation-duration: .45s;
}

.side__navbar.open ul a li:nth-of-type(3) {
  animation-duration: .5s;
}

.side__navbar.open ul a li:nth-of-type(4) {
  animation-duration: .55s;
}

.side__navbar.open ul a li:nth-of-type(5) {
  animation-duration: .6s;
}

.side__navbar ul a li {
  text-align: center;
  transform-origin: center;
  cursor: pointer;
  background: #fff3;
  border: 3px solid #fff3;
  border-radius: 30px;
  margin: 12px 5px;
  padding: 15px;
  transition: all .38s;
  transform: scale(1);
  box-shadow: 0 4px 8px -2px #060c359e;
}

.side__navbar ul a li:hover, .side__navbar ul a li:active {
  background: #ffffff59;
  transform: scale(1.05);
  box-shadow: 0 8px 15px -2px #060c359e;
}

.side__navbar ul a {
  color: var(--color-white);
  width: 85%;
  outline: none;
  font-size: 20px;
  font-weight: 300;
  text-decoration: none;
}

.navbar {
  width: 100%;
  background-color: var(--color-bg-navbar);
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  transition: background .38s, box-shadow .38s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.navbar.sticky {
  background-color: var(--color-white);
  position: fixed;
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
}

.navbar.sticky > .navbar__logo, .navbar.sticky > .menu__list {
  animation: .7s ease-in-out alternate-reverse toUp;
}

.navbar__logo {
  float: left;
  outline: 0;
}

.navbar__logo:hover > .img__logo {
  transform: scale(1.05);
}

.img__logo {
  height: auto;
  transition: transform .38s;
  transform: scale(1);
}

.menu__list {
  flex-flow: wrap;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.menu__list > .menu__item {
  cursor: pointer;
  margin: 10px;
  padding: 5px;
  position: relative;
}

.menu__list > .menu__item:before {
  content: " ";
  width: 0%;
  height: 3px;
  background: linear-gradient(to right, var(--color-gradient-line-left), var(--color-gradient-line-right));
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  border-radius: 3px;
  transition: width .3s .1s, opacity .3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.menu__list > .menu__item:hover:before, .menu__list > .menu__item:active:before, .menu__list > .menu__item.actived:before {
  width: 100%;
  opacity: 1;
}

.menu__list > .menu__item > .menu__link {
  color: var(--color-texts);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  outline: none;
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  display: block;
}

.wrapper__menu {
  z-index: 9999;
  position: fixed;
  right: 15px;
}

.btn__hamburger {
  cursor: pointer;
  background: #ffffff4d;
  border: 0;
  border-radius: 50%;
  outline: none;
  transition: all .38s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 15px 5px #0003;
}

.btn__hamburger:hover, .btn__hamburger:active {
  background: var(--color-white);
  box-shadow: 0 0 25px 6px #37557c99;
}

.btn__hamburger.actived span {
  transform: rotate(135deg);
}

.btn__hamburger.actived span:before {
  width: 100%;
  opacity: 0;
  transform: translate(0, 10px)rotate(-90deg);
}

.btn__hamburger.actived span:after {
  width: 100%;
  opacity: 1;
  transform: translate(0, -10px)rotate(90deg);
}

.btn__hamburger span, .btn__hamburger span:before, .btn__hamburger span:after {
  height: 4px;
  background: var(--color-secondary);
  border-radius: 4px;
  margin: auto;
  position: absolute;
}

.btn__hamburger span {
  transition: transform .3s;
  top: calc(50% - 2px);
}

.btn__hamburger span:before, .btn__hamburger span:after {
  content: " ";
  transition: transform .4s, width .4s, opacity .1s;
  left: 0;
}

.btn__hamburger span:before {
  bottom: 10px;
}

.btn__hamburger span:after {
  top: 10px;
}

.container__hero {
  height: 100%;
  width: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  position: relative;
}

.link__banner {
  text-align: center;
  height: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0 .6rem .6rem;
  display: flex;
}

.link__banner__button {
  height: 50px;
  background-color: var(--color-btn-gray);
  color: var(--color-white);
  letter-spacing: 1.5px;
  font-weight: 300;
}

.hero__main {
  background-color: var(--color-hero-bg);
  background-image: url("bg-main.77c7190e.png");
}

.hero__inner {
  width: 100%;
  height: 100vh;
  display: flex;
}

.inner__column {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.hero__texts.main {
  letter-spacing: 4px;
}

.hero__texts.main.left {
  color: var(--color-white);
  text-shadow: 1px 2px 5px #00000080;
}

.hero__texts.main.right {
  text-align: center;
  text-shadow: 1px 2px 5px #00000026;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero__texts.left > h3 {
  font-weight: 300;
}

.hero__texts.left > h2 {
  font-weight: bolder;
}

.hero__texts.right > h2 {
  padding-top: 100px;
  font-weight: 300;
}

.btn__demo {
  color: var(--color-white);
  letter-spacing: 1.5px;
  height: 50px;
  background-color: #6f6f6f;
  font-weight: 300;
}

.btn__demo:hover, .btn__demo:active {
  background-color: #4b4b4b;
}

.section__description {
  background-color: var(--color-white);
  background-image: url("bg-bridge.04bff0db.png"), linear-gradient(var(--color-gradient-section-top), var(--color-gradient-section-bottom));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.text__goal {
  padding: 10px;
  font-weight: 300;
}

.divider__line {
  height: 3px;
  background-image: url("line-red.ec6383f4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 15px auto;
}

.divider__line.space {
  margin: 45px auto;
}

.container__media {
  display: flex;
}

.iframe__container {
  height: 0;
  padding-top: 30px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.iframe__container iframe, .iframe__container object, .iframe__container embed {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container__texts {
  background-color: #ffffff65;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  display: flex;
}

.container__texts > h3 {
  text-align: center;
  width: 80%;
}

.container__texts > p {
  text-align: center;
  font-weight: 300;
}

.section__clients {
  background-image: linear-gradient(var(--color-white) 50%, var(--color-gradient-section-bottom) 100%);
}

.title__clients {
  position: relative;
}

.title__clients:before {
  width: 75%;
  height: 3.5px;
  background: linear-gradient(to right, var(--color-gradient-line-left), var(--color-gradient-line-right));
  position: absolute;
  bottom: -15px;
  left: 0;
}

.container__customers, .container__statistic {
  justify-content: center;
  align-items: stretch;
  padding: 20px 45px;
  display: flex;
}

.container__customers > .brand {
  object-fit: contain;
  margin: 15px 20px;
}

.swiper-container {
  height: 250px;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  z-index: 0 !important;
}

.swiper-slide {
  height: 100%;
  width: 100%;
  display: flex;
}

.swiper-slide img {
  object-fit: none;
  width: 100%;
}

.swiper-button-next, .swiper-button-prev {
  border: 0;
  outline: none;
  color: var(--color-gradient-line-right) !important;
}

.container__statistic > .statistic__item {
  background: var(--color-white);
  transform-origin: center;
  border-radius: 3px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  transition: transform .38s linear, box-shadow .135s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  transform: scale(1);
}

.container__statistic > .statistic__item:hover, .container__statistic > .statistic__item:active {
  transform: scale(1.1);
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.container__statistic > .statistic__item > * {
  letter-spacing: 1.25px;
}

.container__statistic > .statistic__item > h3 {
  margin: 5px;
  font-size: 38px;
}

.container__statistic > .statistic__item > small {
  font-size: 18px;
  font-weight: 300;
}

.container__usgs {
  background: var(--color-white);
  justify-content: space-between;
  align-items: center;
  display: flex;
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
}

.container__usgs > .description {
  font-weight: 500;
}

.container__usgs > .logo__usgs {
  height: auto;
}

.container__footer {
  background-image: url("bg-footer.04317bcf.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 50px;
  display: flex;
}

.container__footer > small {
  text-align: center;
  color: var(--color-footer-text);
  margin: 2.5px;
  font-size: 18px;
  font-weight: 300;
}

.container__footer > small > a {
  color: inherit;
  border: 0;
  outline: none;
  text-decoration: none;
}

.container__footer > .row__contact, .container__footer > .row__social {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container__footer > .row__social {
  margin-bottom: -10px;
}

.container__footer > .row__social > .link {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  color: inherit;
  outline: none;
  text-decoration: none;
}

.container__footer > .row__social > .link > .icon {
  width: 35px;
  height: 35px;
  box-shadow: none;
  transform-origin: center;
  background-color: #0000;
  border-radius: 50%;
  margin: 5px;
  transition: all .38s;
  transform: scale(1);
}

.container__footer > .row__social > .link > .icon:hover {
  background-color: #ffffff85;
  transform: scale(1.1);
  box-shadow: 0 5px 15px #fa000030, 0 6px 6px #fa00003b;
}

.container__footer > .row__contact > * {
  margin: 2px 5px;
  font-size: 16px;
}

.container__footer > .copyright {
  margin-top: 15px;
  margin-bottom: -15px;
  font-size: 16px;
}

.hero__about {
  background-color: var(--color-white);
  background-image: url("bg-circle.588d1c3b.png");
  background-position-y: 76px;
  background-attachment: unset;
}

.hero__texts.about {
  letter-spacing: 2px;
}

.hero__texts.about.left {
  color: var(--color-black);
}

.hero__texts.about.left > h2 {
  font-weight: bold;
}

.hero__texts.about.left > p {
  font-weight: 300;
}

.hero__texts.about.left, .hero__texts.about.right {
  flex-direction: column;
  display: flex;
}

.hero__texts.about.right > img {
  margin: 30px 0;
}

.btn__solutions {
  color: var(--color-white);
  height: 50px;
  background-color: #bf072b;
  font-size: 23px;
  font-weight: 300;
}

.btn__solutions:hover, .btn__solutions:active {
  background-color: #970824;
}

.section__collaboration {
  background-color: var(--color-white);
  background-image: url("bg-bridge-white.b18f3f7a.png");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.text__collaborates {
  padding: 10px;
  font-weight: 300;
}

.section__awards {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  padding: 20px 0;
  display: flex;
}

.title__awards {
  font-weight: bolder;
}

.container__awards {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: stretch;
  padding: 20px 45px;
  display: flex;
}

.container__awards > .award {
  object-fit: contain;
  margin: 30px 75px;
}

.section__press {
  background-color: var(--color-white);
  background-image: url("bg-duo-circle.33edc0b0.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title__press {
  font-weight: bold;
}

.iframe__press {
  width: 40%;
  height: 0;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 23.25%;
  position: relative;
  overflow: hidden;
}

.iframe__press iframe {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container__videos {
  padding: 15px;
}

.container__videos .video {
  width: 448px;
  flex-flow: column;
  margin: 15px auto;
  display: flex;
}

.container__videos iframe {
  height: 400px;
}

.container__videos .video > .video__title {
  border-bottom: 3px solid var(--color-primary);
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.container__videos .video > .video__subheader {
  margin-top: 5px;
  font-weight: 300;
}

.container__press, .container__videos {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.container__press > a {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  color: inherit;
  outline: none;
  text-decoration: none;
}

.note {
  cursor: pointer;
  background-color: var(--color-white);
  border: 1px solid #7b7b7b29;
  border-radius: 4px;
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  transition: all .38s cubic-bezier(.25, .8, .25, 1);
  display: flex;
  overflow: hidden;
  transform: scale(1);
  box-shadow: 0 3px 6px #00000029;
}

.note:hover, .note:active {
  transform: scale(1.05);
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.header__note {
  filter: brightness(55%);
  transition: filter .5s cubic-bezier(.43, .41, .22, .91);
  overflow: hidden;
}

.note:hover > .header__note {
  filter: brightness();
}

.header__note > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  -webkit-user-select: none;
  user-select: none;
  margin: 0 auto;
  display: block;
}

.body__note:before {
  content: " ";
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--color-gradient-line-left), var(--color-gradient-line-right));
  position: absolute;
  top: 0;
  left: 0;
}

.body__note {
  flex-direction: column;
  flex: auto;
  justify-content: center;
  padding: 10px;
  display: flex;
  position: relative;
}

.body__note > p {
  margin: 5px;
  line-height: 24px;
  position: relative;
}

.body__note > p:before {
  content: " ";
  height: 3px;
  width: 68px;
  background: var(--color-primary);
  position: absolute;
  top: 21px;
  left: -2px;
}

.hero__solutions {
  background-color: var(--color-hero-bg);
  background-image: url("bg-crack.75dc9569.png");
}

.title__solutions {
  font-weight: bolder;
}

.container__solutions {
  align-items: stretch;
  display: flex;
}

.item > p {
  text-align: center;
  width: 85%;
  margin: 2px 0;
  font-size: 22px;
  font-weight: 300;
}

.item > p > strong {
  color: var(--color-primary);
  font-size: inherit;
}

.circle__item {
  background-image: url("circle.460f6bfb.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.circle__item:before, .circle__item:after {
  content: " ";
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle__item:after {
  animation: 1.75s ease-in-out infinite wave-border-blue;
  transform: translate(-50%, -50%)scale(1);
}

.circle__item > img {
  height: auto;
  object-fit: contain;
  margin-top: -10px;
}

.circle__item.epicenter > img {
  width: 200px;
}

.section__form {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section__form.solutions {
  background-color: var(--color-white);
  background-image: url("bg-bridge-2.dfc6f1cb.png");
  background-attachment: fixed;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.text__solutions {
  font-weight: 300;
}

.section__system {
  background-image: url("bg-circle-blue.523513e7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title__system {
  color: var(--color-primary);
}

.list__features {
  margin-left: -8px;
  padding: 0;
  list-style: none;
}

.list__features > li {
  background: url("bullet.932d1202.png") 8px 6px / 20px no-repeat;
  padding-top: 4px;
  padding-left: 38px;
  font-weight: 300;
}

.container__system {
  align-items: stretch;
  margin-top: 50px;
  display: flex;
}

.feature {
  margin: 15px;
}

.img__feature {
  width: 125px;
  height: 125px;
  position: relative;
}

.img__feature:before {
  content: " ";
  width: 100%;
  height: 100%;
  background-color: #f32a4b10;
  border: 1px solid #f32a4b25;
  border-radius: 50%;
  animation: 1.5s linear infinite wave-red;
  position: absolute;
  top: 0;
  left: 0;
}

.img__feature > img {
  width: 125px;
  height: auto;
  object-fit: contain;
}

.feature > small {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
}

.hero__works {
  background-image: url("bg-crack-2.dbcf5715.png");
  background-position: 100% 100%;
}

.title__works {
  font-weight: bolder;
}

.container__process {
  align-items: stretch;
  display: flex;
}

.process > h3 {
  text-align: center;
}

.process > p {
  text-align: center;
  width: 85%;
  font-weight: 300;
}

.section__eews {
  background-color: var(--color-white);
  background-image: url("bg-circle.588d1c3b.png");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.title__eews {
  color: #f32a4b;
  margin-bottom: 25px;
  font-weight: bolder;
}

.container__eews {
  justify-content: center;
  align-items: stretch;
  transition: all .38s;
  display: flex;
}

.container__eews > .side__left, .container__eews > .side__right {
  flex-flow: column;
  display: flex;
}

.container__eews > .side__left {
  justify-content: space-evenly;
  padding-right: 35px;
}

.container__eews > .side__right {
  justify-content: center;
  align-items: center;
}

.container__eews > .side__left > p {
  font-weight: 300;
}

.container__eews > .side__right > .img__device {
  position: relative;
}

.container__eews > .side__right > .img__device:before {
  content: " ";
  width: 100%;
  height: 100%;
  background-color: #0000;
  border-radius: 50%;
  animation: 1.5s linear infinite wave-border-red;
  position: absolute;
  top: 0;
  left: 0;
}

.container__eews > .side__right > .img__device > img {
  height: auto;
  object-fit: contain;
  animation: 5s ease-in-out infinite alternate zoom-in-out;
}

.hero__contact {
  background-image: url("bg-bridge-blue.afa57266.png");
  background-color: var(--color-secondary);
  background-attachment: fixed;
}

.title__contact {
  margin-bottom: 25px;
  font-weight: bolder;
}

.section__form.contact {
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  display: flex;
}

.section__form.contact > .side__left.contact {
  padding-top: 25px;
}

.section__form.contact > .side__right.contact {
  justify-content: stretch;
  align-items: stretch;
  padding-top: 50px;
  display: flex;
}

.section__form.contact > .side__right.contact > form-contact {
  flex: 1;
}

.list__contact {
  margin-left: -8px;
  padding: 0;
  list-style: none;
}

.list__contact > li {
  color: var(--color-secondary);
  background: url("bullet.932d1202.png") 8px 16px / 20px no-repeat;
  margin: 35px 0;
  padding-top: 4px;
  padding-left: 38px;
  font-size: 36px;
}

.hero__expo {
  background-image: url("bg-main.77c7190e.png");
  background-color: var(--color-secondary);
  background-attachment: fixed;
}

@keyframes toUp {
  to {
    opacity: 0;
    transform: translateY(15px);
  }

  from {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wave-border-blue {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%)scale(1);
    box-shadow: inset 0 0 #0000, 0 0 #8698ab;
  }

  50% {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1.1);
    box-shadow: inset 0 0 #0000, 0 0 0 10px #8698ab;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%)scale(1.2);
    box-shadow: inset 0 0 #0000, 0 0 0 10px #8698ab;
  }
}

@keyframes wave-red {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  75% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes wave-border-red {
  0% {
    opacity: 0;
    transform: scale(1);
    box-shadow: inset 0 0 #0000, 0 0 #f32a4b25;
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: inset 0 0 #0000, 0 0 0 10px #f32a4b55;
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
    box-shadow: inset 0 0 #0000, 0 0 0 10px #f32a4b35;
  }
}

@keyframes zoom-in-out {
  from {
    transform: scale(.95);
  }

  to {
    transform: scale(1);
  }
}

@keyframes pulse {
  from {
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes background-change {
  from {
    background-color: #2b3952;
  }

  to {
    background-color: #536280;
  }
}

@media only screen and (max-width: 319px) {
  .swiper-pagination {
    display: none !important;
  }

  .link__banner__button {
    width: 80%;
    margin: auto;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 320px) {
  .preloader.active .circles .pulse.circle__1 {
    width: 300px;
    height: 300px;
  }

  .preloader.active .circles .pulse.circle__2 {
    width: 250px;
    height: 250px;
  }

  .preloader.active .circles .pulse.circle__3 {
    width: 200px;
    height: 200px;
  }

  .preloader .circles .logo {
    width: 150px;
    height: 150px;
  }

  .menu__list {
    display: none;
  }

  .img__logo {
    width: 90px;
    margin-bottom: -1px;
  }

  .wrapper__menu {
    display: block;
  }

  .btn__hamburger {
    width: 50px;
    height: 50px;
  }

  .btn__hamburger span, .btn__hamburger span:before, .btn__hamburger span:after {
    width: 32px;
  }

  .btn__hamburger span {
    left: calc(50% - 16px);
  }

  .container__hero {
    background-position: center;
    background-attachment: scroll;
  }

  .container__footer {
    background-position: 0;
  }

  .hero__inner, .section__description, .section__clients, .section__collaboration, .section__press, .section__form, .section__system, .section__eews {
    padding: 20px;
  }

  .hero__inner {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .link__banner__button {
    width: 80%;
    margin: auto;
    font-size: 1rem;
  }

  .isotype {
    background: none;
    background-size: unset;
    background-position: unset;
    background-repeat: unset;
  }

  .hero__texts.left > h3 {
    text-align: center;
    font-size: 18px;
  }

  .hero__texts.left > h2 {
    text-align: center;
  }

  .hero__texts.left > h2, .hero__texts.right > h2 {
    font-size: 24px;
  }

  .hero__texts.main.right, .hero__texts.main.left {
    width: 80%;
  }

  .hero__texts.main.left {
    padding-top: 50px;
  }

  .hero__texts.main.right {
    margin-top: -125px;
  }

  .btn__demo {
    margin-top: unset;
    width: 250px;
    font-size: 20px;
  }

  .section__description, .section__collaboration, .section__form {
    height: 100%;
  }

  .text__goal, .text__collaborates, .text__solutions {
    letter-spacing: .95px;
    font-size: 20px;
  }

  .divider__line {
    width: 95%;
  }

  .container__media {
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .iframe__container, .container__texts {
    flex: .5;
  }

  .iframe__container {
    width: 95%;
    margin: auto;
  }

  .container__texts {
    margin-top: 30px;
    margin-right: unset;
    justify-content: center;
    align-items: center;
  }

  .container__texts > h3 {
    font-size: 22px;
  }

  .container__texts > p {
    margin-top: unset;
    font-size: 24px;
  }

  .title__clients, .title__awards, .title__press, .hero__texts.about.left > h2, .title__solutions, .title__system, .title__works, .title__eews, .title__contact {
    font-size: 30px;
  }

  .title__clients {
    text-align: center;
    width: auto;
    margin: 45px auto;
    padding-left: 0;
  }

  .title__clients:before {
    content: unset;
  }

  .container__customers {
    display: none;
  }

  .swiper-container {
    width: 100%;
    margin-bottom: 20px;
    display: block;
  }

  .swiper-pagination {
    display: none !important;
  }

  .container__usgs, .container__statistic, .section__form.solutions, .container__system, .container__process, .container__eews, .section__form.contact {
    flex-flow: column;
  }

  .container__statistic > .statistic__item {
    height: 160px;
    width: 100%;
    margin: 25px auto;
    box-shadow: 0 10px 20px #00000030, 0 6px 6px #0000003b;
  }

  .container__usgs {
    margin: 30px auto;
    padding: 25px 20px;
  }

  .container__usgs > .description, .container__usgs > .logo__usgs {
    flex: .5;
  }

  .container__usgs > .description {
    text-align: center;
    letter-spacing: .55px;
    padding-left: 0;
    font-size: 22px;
  }

  .container__usgs > .logo__usgs {
    width: 200px;
    margin-left: unset;
    object-fit: contain;
  }

  .hero__texts.about {
    letter-spacing: .95px;
  }

  .hero__texts.about.left {
    width: 100%;
    justify-content: center;
  }

  .hero__texts.about.left > h2 {
    margin-bottom: 25px;
  }

  .hero__texts.about.left > p {
    text-align: center;
    font-size: 22px;
    font-weight: 300;
  }

  .btn__solutions {
    width: 250px;
    letter-spacing: 1.1px;
    margin: 25px auto;
    font-size: 18px;
  }

  .hero__texts.about.right {
    display: none;
  }

  .text__collaborates {
    width: 100%;
    margin: auto;
  }

  .title__awards, .title__press, .title__solutions, .title__system, .title__works, .title__eews, .title__contact {
    text-align: center;
  }

  .container__awards > .award {
    object-fit: unset;
    width: 200px;
    height: auto;
    margin: 20px;
  }

  .title__press {
    margin-bottom: 50px;
  }

  .container__press {
    margin-top: 50px;
    padding: 0;
  }

  .note {
    max-width: 95%;
    width: 95%;
    margin: 15px auto;
  }

  .title__solutions, .title__works {
    margin-top: 80px;
    margin-bottom: 20px;
  }

  .container__solutions, .container__process {
    flex-flow: column;
    justify-content: flex-start;
    margin: auto -20px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .item:nth-child(1) {
    padding-top: 15px;
  }

  .item {
    margin: 15px 20px;
    display: block;
  }

  .item > p, .item > ul > li {
    width: 85%;
    margin: auto;
    font-size: 18px;
  }

  .item > ul > li {
    font-weight: 300;
  }

  .circle__item {
    width: 125px;
    height: 125px;
    margin: auto auto 15px;
  }

  .circle__item:before, .circle__item:after {
    width: 140px;
    height: 140px;
  }

  .circle__item > img {
    width: 90px;
  }

  .section__form > .side__left.solutions, .section__form > .side__right.solution {
    flex: 50%;
  }

  .section__form > .side__left.solutions {
    padding-right: unset;
  }

  .text__solutions:not(:first-of-type) {
    display: none;
  }

  .feature {
    margin: 20px auto;
    display: block;
  }

  .img__feature {
    margin: auto;
  }

  .list__features > li {
    width: 100%;
    margin: 20px auto;
    font-size: 20px;
  }

  .container__system, .container__process {
    height: 530px;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .container__process {
    height: unset;
  }

  .process {
    margin: 50px 0;
    display: block;
  }

  .process:nth-child(1) {
    padding-top: 35px;
  }

  .process > h3 {
    text-align: center;
    font-size: 24px;
  }

  .process > p {
    margin: 15px auto;
    font-size: 18px;
  }

  .process > img {
    width: 100%;
    margin: auto;
    display: block;
  }

  .container__eews {
    flex-direction: column-reverse;
  }

  .container__eews > .side__left {
    padding-right: unset;
    align-items: center;
  }

  .container__eews > .side__left > p {
    font-size: 18px;
    line-height: unset;
  }

  .container__eews > .side__right > .img__device {
    margin: 50px auto;
  }

  .container__eews > .side__right > .img__device > img {
    width: 255px;
  }

  .hero__contact {
    height: 100%;
  }

  .list__contact {
    display: none;
  }

  .section__form.contact {
    width: 95%;
  }

  .section__form.contact > .side__right.contact {
    margin: 0 -5px;
    padding-top: 25px;
  }

  .hero__expo {
    height: 100%;
  }

  @keyframes wave-border-blue {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%)scale(1);
      box-shadow: inset 0 0 #0000, 0 0 #8698ab;
    }

    50% {
      opacity: 1;
      transform: translate(-50%, -50%)scale(1.1);
      box-shadow: inset 0 0 #0000, 0 0 0 3px #8698ab;
    }

    100% {
      opacity: 0;
      transform: translate(-50%, -50%)scale(1.2);
      box-shadow: inset 0 0 #0000, 0 0 0 3px #8698ab;
    }
  }
}

@media only screen and (min-width: 350px) {
  .swiper-pagination {
    display: block !important;
  }

  .container__videos iframe {
    height: 300px;
  }

  .main__banner img {
    height: auto;
  }
}

@media only screen and (min-width: 375px) {
  .hero__texts.left > h3 {
    font-size: 22px;
  }

  .hero__texts.left > h2, .hero__texts.right > h2 {
    font-size: 30px;
  }

  .btn__demo {
    margin-top: -15px;
  }

  .container__awards > .award {
    object-fit: contain;
    width: unset;
    height: unset;
    margin: 30px;
  }

  .text__solutions:not(:first-of-type) {
    margin: 20px auto;
    display: block;
  }

  .text__solutions:last-child {
    display: none;
  }

  .container__videos iframe {
    height: 350px;
  }
}

@media only screen and (min-width: 480px) {
  .img__logo {
    width: 98px;
  }

  .btn__hamburger {
    width: 60px;
    height: 60px;
  }

  .btn__hamburger span, .btn__hamburger span:before, .btn__hamburger span:after {
    width: 36px;
  }

  .btn__hamburger span {
    left: calc(50% - 18px);
  }

  .hero__texts.left > h3 {
    font-size: 25px;
  }

  .hero__texts.left > h2, .hero__texts.right > h2 {
    font-size: 35px;
  }

  .container__statistic > .statistic__item {
    width: 85%;
  }

  .btn__demo {
    margin-top: unset;
  }

  .container__usgs > .description {
    letter-spacing: 1px;
    font-size: 28px;
  }

  .hero__texts.about.left > h2 {
    margin-bottom: 5px;
  }

  .hero__texts.about.right {
    display: flex;
  }

  .btn__solutions {
    margin: auto;
  }

  .hero__texts.about.right > img {
    width: 185px;
    margin-bottom: -25px;
  }

  .container__videos iframe {
    height: 380px;
  }

  .item {
    margin: 20px;
  }

  .item > p, .item > ul > li {
    width: 75%;
    font-size: 20px;
  }

  .circle__item {
    margin-bottom: 20px;
  }

  .text__solutions:nth-child(2) {
    margin: auto;
  }

  .text__solutions:last-child {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .preloader.active .circles .pulse.circle__1 {
    width: 350px;
    height: 350px;
  }

  .preloader.active .circles .pulse.circle__2 {
    width: 300px;
    height: 300px;
  }

  .preloader.active .circles .pulse.circle__3 {
    width: 250px;
    height: 250px;
  }

  .preloader .circles .logo {
    width: 200px;
    height: 200px;
  }

  .img__logo {
    width: 100px;
    margin-bottom: unset;
  }

  .swiper-container {
    width: 75%;
    margin: auto;
    display: block;
  }

  .iframe__container {
    width: 85%;
  }

  .container__usgs > .logo__usgs {
    width: 250px;
  }

  .container__usgs > .description {
    font-size: 32px;
  }

  .hero__texts.about.left > h2 {
    margin-bottom: auto;
  }

  .hero__texts.about.left > p {
    margin: 30px auto;
  }

  .btn__solutions {
    margin: auto;
  }

  .hero__texts.about.right > img {
    width: 275px;
    margin-bottom: auto;
  }

  .note {
    max-width: 85%;
    width: 85%;
  }

  .section__form > .side__right.solutions {
    margin: auto;
    padding-top: 25px;
  }

  .list__features > li {
    width: 85%;
    margin: 20px auto 20px 0;
    font-size: 22px;
  }

  .container__system {
    height: unset;
    overflow: unset;
    flex-flow: wrap;
    justify-content: center;
  }

  .container__eews > .side__left > p {
    width: 100%;
    text-align: center;
    line-height: 20px;
  }

  .process > img {
    width: 70%;
  }

  .section__form.contact {
    width: 500px;
  }
}

@media only screen and (min-width: 768px) {
  .link__banner__button {
    width: 85%;
    font-size: 1.25rem;
  }

  .hero__texts.about.right > img {
    width: 350px;
    margin-bottom: auto;
  }

  .note {
    max-width: 625px;
    width: unset;
  }

  .container__solutions {
    max-width: 100%;
    flex-flow: wrap;
    justify-content: center;
    padding: 0 100px;
  }

  .item:nth-child(1) {
    padding-top: unset;
  }

  .feature {
    margin: 20px 25px;
  }

  .container__eews > .side__right > .img__device > img {
    width: 335px;
  }
}

@media only screen and (min-width: 900px) {
  .preloader.active .circles .pulse.circle__1 {
    width: 400px;
    height: 400px;
  }

  .preloader.active .circles .pulse.circle__2 {
    width: 350px;
    height: 350px;
  }

  .preloader.active .circles .pulse.circle__3 {
    width: 300px;
    height: 300px;
    opacity: .65;
  }

  .preloader .circles .logo {
    width: 250px;
    height: 250px;
  }

  .menu__list {
    display: flex;
  }

  .wrapper__menu {
    display: none;
  }

  .img__logo {
    width: 105px;
  }

  .container__hero {
    background-position: center;
  }

  .isotype {
    background: url("isotype.c0cf5086.png") 50% 75px / contain no-repeat;
  }

  .hero__inner, .section__description, .section__clients, .section__collaboration, .section__press, .section__form {
    padding: 25px;
  }

  .hero__inner {
    flex-flow: row;
    justify-content: space-between;
    align-items: stretch;
  }

  .hero__texts.main.left {
    width: calc(50% - 50px);
    padding-top: 100px;
  }

  .hero__texts.left > h3 {
    text-align: left;
    font-size: 35px;
  }

  .hero__texts.left > h2 {
    text-align: left;
  }

  .hero__texts.main.right {
    width: calc(50% - 150px);
  }

  .hero__texts.left > h2, .hero__texts.right > h2, .hero__texts.about.left > h2, .title__clients, .title__awards, .title__press, .title__solutions, .title__system, .title__works, .title__eews, .title__contact {
    font-size: 40px;
  }

  .hero__texts.main.right {
    margin-top: unset;
  }

  .btn__demo {
    width: 250px;
    font-size: 21px;
  }

  .section__description, .section__collaboration {
    height: 100vh;
  }

  .text__goal, .text__collaborates, .text__solutions {
    text-align: center;
    letter-spacing: 1.5px;
    font-size: 26px;
  }

  .divider__line {
    width: 75%;
  }

  .container__media {
    flex-flow: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 50px;
  }

  .iframe__container, .container__texts {
    flex: .48;
  }

  .iframe__container {
    width: 100%;
    height: 345px;
    padding-top: 30px;
    padding-bottom: 0;
  }

  .container__texts {
    margin-top: unset;
    justify-content: space-evenly;
    margin-right: -25px;
  }

  .container__texts > h3 {
    font-size: 28px;
  }

  .container__texts > p {
    margin-top: 0;
    font-size: 22px;
  }

  .title__clients {
    text-align: left;
    width: 375px;
    margin: 45px 0 45px -25px;
    padding-left: 25px;
  }

  .title__clients:before {
    content: " ";
  }

  .container__customers {
    display: flex;
  }

  .swiper-container {
    display: none;
  }

  .container__usgs > .description {
    text-align: center;
    letter-spacing: .55px;
    padding-left: 0;
    font-size: 22px;
  }

  .container__usgs > .logo__usgs {
    width: 200px;
    margin-left: unset;
  }

  .container__usgs, .container__statistic, .container__customers, .section__form.solutions, .container__process {
    flex-flow: wrap;
  }

  .container__statistic > .statistic__item {
    width: 300px;
    height: 160px;
    margin: 25px 35px;
    box-shadow: 0 1px 1px #42424214, 0 1px 3px 1px #42424229;
  }

  .container__usgs {
    margin: 32px -25px 0;
  }

  .container__usgs > .description {
    flex: .7;
  }

  .container__usgs > .logo__usgs {
    flex: .3;
  }

  .container__usgs > .description {
    text-align: left;
    letter-spacing: 1.43px;
    padding-left: 15px;
    font-size: 28px;
  }

  .container__usgs > .logo__usgs {
    width: 200px;
    object-fit: none;
    margin-left: 30px;
  }

  .hero__texts.about {
    letter-spacing: 2px;
  }

  .hero__texts.about.left {
    width: calc(50% - 15px);
    justify-content: space-around;
    padding-top: 25px;
  }

  .hero__texts.about.left > h2 {
    margin-bottom: 0;
  }

  .hero__texts.about.left > p {
    text-align: left;
    font-size: 26px;
  }

  .hero__texts.about.right {
    display: flex;
  }

  .hero__texts.about.right > img {
    width: 450px;
    margin: auto;
  }

  .btn__solutions {
    width: 300px;
    letter-spacing: 1.5px;
    margin: 0 auto;
    font-size: 21px;
  }

  .text__collaborates {
    width: 85%;
    margin: 25px auto auto;
  }

  .title__awards, .title__press, .title__solutions, .title__system, .title__works, .title__eews {
    text-align: left;
  }

  .container__press {
    max-width: unset;
    margin-top: 25px;
    padding: 15px;
  }

  .note {
    max-width: 400px;
    margin: 15px auto;
  }

  .iframe__container.press {
    width: 50%;
  }

  .hero__solutions {
    height: 100%;
  }

  .inner__column {
    flex-flow: column !important;
  }

  .container__solutions {
    max-width: 85%;
    padding: 0;
  }

  .title__solutions {
    letter-spacing: 1.2px;
    margin-top: 80px;
    margin-bottom: auto;
  }

  .container__solutions, .container__process {
    overflow: unset;
    justify-content: center;
    margin: auto;
  }

  .item, .feature, .process {
    flex-flow: column;
    flex: 1;
    align-items: center;
    margin: 15px 35px;
  }

  .item > p, .item > ul > li {
    font-size: 22px;
  }

  .item > ul > li {
    width: 100%;
  }

  .circle__item {
    width: 185px;
    height: 185px;
    margin-bottom: 25px;
  }

  .circle__item:before, .circle__item:after {
    width: 200px;
    height: 200px;
  }

  .circle__item > img {
    width: 130px;
  }

  .section__form.solutions {
    flex-wrap: nowrap;
  }

  .text__solutions {
    text-align: justify;
  }

  .section__form > .side__left.solutions {
    padding-right: 44px;
  }

  .list__features > li {
    width: 545px;
  }

  .feature, .process {
    display: flex;
  }

  .process > img {
    width: 100%;
    display: unset;
    margin: 0;
  }

  .container__process {
    margin: auto;
  }

  .process:nth-child(1) {
    padding-top: unset;
  }

  .container__eews {
    flex-flow: row;
  }

  .container__eews > .side__left {
    align-items: unset;
    flex: 60%;
    padding-right: 75px;
    line-height: 30px;
  }

  .container__eews > .side__right {
    flex: 40%;
  }

  .container__eews > .side__left > p {
    text-align: justify;
    font-weight: 300;
    line-height: 40px;
  }

  .list__contact {
    display: block;
  }

  .section__form.contact {
    width: unset;
    flex-flow: row;
  }

  .section__form.contact > .side__left.contact {
    flex: 50%;
  }

  .section__form.contact > .side__right.contact {
    flex: 50%;
    margin-top: 80px;
  }

  .list__contact > li {
    font-size: 28px;
  }

  @keyframes wave-border-blue {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%)scale(1);
      box-shadow: inset 0 0 #0000, 0 0 #8698ab;
    }

    50% {
      opacity: 1;
      transform: translate(-50%, -50%)scale(1.1);
      box-shadow: inset 0 0 #0000, 0 0 0 10px #8698ab;
    }

    100% {
      opacity: 0;
      transform: translate(-50%, -50%)scale(1.2);
      box-shadow: inset 0 0 #0000, 0 0 0 10px #8698ab;
    }
  }

  .link__banner__button {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .link__banner__button {
    font-size: 1.5rem;
    width: auto !important;
  }

  .hero__texts.main {
    line-height: 48px;
  }

  .hero__texts.left > h3 {
    font-size: 35px;
  }

  .hero__texts.left > h2, .hero__texts.right > h2 {
    font-size: 42px;
  }

  .container__media {
    margin-top: 2rem;
  }

  .container__texts > h3 {
    font-size: 30px;
  }

  .container__texts > p {
    margin-top: unset;
    font-size: 26px;
  }

  .container__statistic > .statistic__item {
    width: 240px;
    height: 126px;
    margin: 15px 25px;
  }

  .note {
    max-width: 450px;
    margin: 25px 20px;
  }

  .text__solutions, .title__system {
    text-align: left;
  }

  .section__form > .side__left.solutions {
    flex: 60%;
    padding-right: 12.5%;
  }

  .section__form > .side__right.solutions {
    flex: 40%;
  }

  .process {
    margin: 15px;
  }

  .process > img {
    width: 85%;
  }

  .process > h3 {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .process > p {
    font-size: 19px;
  }

  .container__eews > .side__left > p {
    width: 88%;
    font-size: 24px;
  }

  .link__banner__button {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .hero__inner, .section__description, .section__clients, .section__collaboration, .section__press, .section__form, .section__system, .section__eews {
    padding: 35px;
  }

  .hero__texts.main {
    line-height: 52px;
  }

  .hero__texts.left > h3 {
    font-size: 38px;
  }

  .hero__texts.left > h2, .hero__texts.right > h2 {
    font-size: 45px;
  }

  .container__texts {
    margin-right: -35px;
  }

  .hero__texts.main.right {
    width: calc(50% - 300px);
  }

  .container__media {
    margin-top: 3rem;
  }

  .title__clients, .hero__texts.about.left > h2, .title__awards, .title__press, .title__system, .title__works, .title__contact {
    font-size: 50px;
  }

  .title__clients {
    width: 500px;
    margin: 50px 0 50px -35px;
    padding-left: 35px;
  }

  .container__usgs {
    margin: 32px -35px 0;
    padding: 25px 35px;
  }

  .container__usgs > .description {
    padding-left: 30px;
    font-size: 30px;
  }

  .hero__texts.about {
    letter-spacing: 2px;
  }

  .hero__texts.about.left > h2 {
    margin-bottom: -45px;
  }

  .hero__texts.about.left > p {
    font-size: 28px;
  }

  .hero__texts.about.left {
    padding-top: unset;
    width: calc(50% - 60px);
    justify-content: space-around;
  }

  .btn__solutions {
    font-size: 23px;
  }

  .hero__texts.about.right > img {
    width: 80%;
    margin: auto 0 auto auto;
  }

  .container__awards > .award {
    margin: 30px 75px;
  }

  .hero__solutions {
    height: 100vh;
  }

  .container__solutions {
    max-width: unset;
  }

  .item, .process, .feature {
    margin: 15px;
  }

  .container__eews > .side__left > p {
    font-size: 26px;
  }

  .container__eews > .side__right > .img__device {
    margin: auto;
  }

  .container__eews > .side__right > .img__device > img {
    width: 450px;
  }

  .link__banner__button {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1400px) {
  .hero__inner, .section__description, .section__clients, .section__collaboration, .section__press, .section__form, .section__system, .section__eews {
    padding: 50px;
  }

  .hero__texts.main {
    line-height: 58px;
  }

  .hero__texts.main.left {
    width: calc(50% - 100px);
  }

  .hero__texts.left > h3 {
    font-size: 40px;
  }

  .hero__texts.left > h2, .hero__texts.right > h2 {
    font-size: 50px;
  }

  .container__texts {
    margin-right: -50px;
  }

  .title__clients {
    margin: 65px 0 65px -50px;
    padding-left: 50px;
  }

  .iframe__container {
    height: 500px;
  }

  .container__texts > p {
    margin-top: -30px;
  }

  .container__usgs {
    margin: 32px -50px 0;
    padding: 25px 50px;
  }

  .hero__texts.about.right > img {
    width: 100%;
    margin: 30px -50px 30px auto;
  }

  .item, .feature {
    margin: 20px;
  }

  .hero__contact {
    height: 100vh;
  }

  .link__banner__button {
    font-size: 1.5rem;
  }

  .hero__expo {
    height: 100vh;
  }
}

@media only screen and (min-width: 1600px) {
  .main__banner img {
    height: 742px;
  }

  .container__footer {
    background-position: center;
  }

  .hero__texts.about.right > img {
    width: auto;
  }

  .process {
    margin: 35px auto;
  }

  .process > img {
    width: unset;
  }

  .process > h3 {
    font-size: 36px;
  }

  .process > p {
    font-size: 18px;
  }
}

/*# sourceMappingURL=index.ee92e9b6.css.map */
