:root {
    --color-texts: #111111;
    --color-hero-bg: #364562;
    --color-bg-navbar: #FFFFFF65;
    --color-gradient-line-left: #364764;
    --color-gradient-line-right: #77BDFF;
    --color-gradient-section-top: #f5f5f5;
    --color-gradient-section-bottom: #C5CEDF;
    --color-footer-text: #6b6b6b;
    --color-bg-input: #dbdbdb;
    --color-primary: #F32A4B;
    --color-secondary: #364764;
    --color-white: #FFFFFF;
    --color-gray: #DBDBDB;
    --color-black: #010101;
    --color-btn-gray: #a5a5a5;
}

*,
*:before,
*:after {
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.hidden__scroll {
    overflow: hidden;
}

.color__primary {
    color: var(--color-primary);
}

.color__secondary {
    color: var(--color-secondary);
}

.preloader {
    position: fixed;
    z-index: -99999;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation-play-state: pause;
    transition: all 380ms ease;
    animation: background-change 30s linear infinite alternate;
}

.preloader.active {
    opacity: 1;
    z-index: 99999;
    animation-play-state: running;
}

.preloader.active .circles .logo,
.preloader.active .circles .pulse {
    animation-play-state: running;
}

.preloader .circles {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.preloader .circles .logo {
    background-color: var(--color-white);
    box-shadow: 0 0 0 15px #ffffff15;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 10;
    animation: zoom-in-out 2s linear infinite alternate;
    animation-play-state: paused;
}

.preloader .circles .logo img {
    width: 100%;
}

.preloader .circles .pulse {
    position: absolute;
    background-color: #e2e1e1;
    border-radius: 50%;
    animation: pulse 1.75s infinite ease-in-out backwards;
    animation-play-state: paused;
}

.preloader.active .circles .pulse.circle__1 {
    opacity: 0.9;
    animation-delay: 0;
    z-index: 1;
}

.preloader.active .circles .pulse.circle__2 {
    opacity: 0.75;
    animation-delay: 250ms;
    z-index: 2;
}

.preloader.active .circles .pulse.circle__3 {
    opacity: 0.65;
    animation-delay: 500ms;
    z-index: 3;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    border: none;
    padding: 5px 25px;
    width: 300px;
    border-radius: 25px;
    transition: background 380ms ease, color 380ms ease;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.side__navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -9998;
    visibility: hidden;
    opacity: 0;
    background: linear-gradient(150deg, rgba(95, 104, 128, 1), rgba(13, 25, 40, 1));
    display: flex;
    flex-flow: column nowrap;
    -webkit-transition: all 380ms ease-in-out;
    transition: all 380ms ease-in-out;
}

.side__navbar.open {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    z-index: 9998;
}

.side__navbar .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid var(--color-primary);
    padding: 15px;
    height: 200px;
}

.side__navbar.open .header .blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(54, 69, 98, 0.8), rgba(107, 0, 0, 37%)), url('../img/bg-bridge-blue.png');
    filter: blur(5px);
    background-size: auto;
    background-position: center 75%;
    background-repeat: no-repeat;
    z-index: -1;
}

.side__navbar .header .brand {
    width: 200px;
    height: auto;
    margin-top: 25px;
}

.side__navbar.open .header .brand {
    animation: toUp 750ms 150ms ease-in-out alternate-reverse both;
}

.side__navbar ul {
    margin: 15px 0;
    padding: 10px 5px;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    flex: 1;
}

.side__navbar.open ul a li {
    animation: toUp 500ms 350ms linear alternate-reverse backwards;
}

.side__navbar.open ul a li:nth-of-type(1) {
    animation-duration: 400ms;
}

.side__navbar.open ul a li:nth-of-type(2) {
    animation-duration: 450ms;
}

.side__navbar.open ul a li:nth-of-type(3) {
    animation-duration: 500ms;
}

.side__navbar.open ul a li:nth-of-type(4) {
    animation-duration: 550ms;
}

.side__navbar.open ul a li:nth-of-type(5) {
    animation-duration: 600ms;
}

.side__navbar ul a li {
    background: rgba(255, 255, 255, 20%);
    padding: 15px;
    margin: 12px 5px;
    text-align: center;
    border-radius: 30px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 8px -2px #060c359e;
    transform-origin: center;
    transform: scale(1);
    transition: all 380ms ease;
    cursor: pointer;
}

.side__navbar ul a li:hover,
.side__navbar ul a li:active {
    background: rgba(255, 255, 255, 35%);
    transform: scale(1.05);
    box-shadow: 0 8px 15px -2px #060c359e;
}

.side__navbar ul a {
    color: var(--color-white);
    font-size: 20px;
    text-decoration: none;
    outline: none;
    font-weight: 300;
    width: 85%;
}

.navbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: var(--color-bg-navbar);
    transition: background 380ms ease, box-shadow 380ms ease;
    z-index: 999;
}

.navbar.sticky {
    position: fixed;
    background-color: var(--color-white);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.navbar.sticky>.navbar__logo,
.navbar.sticky>.menu__list {
    animation: toUp 700ms ease-in-out alternate-reverse;
}

.navbar__logo {
    float: left;
    outline: 0;
}

.navbar__logo:hover>.img__logo {
    transform: scale(1.05);
}

.img__logo {
    height: auto;
    transform: scale(1);
    transition: transform 380ms ease;
}

.menu__list {
    align-self: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu__list>.menu__item {
    margin: 10px;
    padding: 5px;
    cursor: pointer;
    position: relative;
}

.menu__list>.menu__item:before {
    content: ' ';
    position: absolute;
    width: 0%;
    height: 3px;
    left: 0;
    bottom: -5px;
    border-radius: 3px;
    background: linear-gradient(to right, var(--color-gradient-line-left), var(--color-gradient-line-right));
    user-select: none;
    opacity: 0;
    transition: width 300ms 100ms, opacity 300ms;
}

.menu__list>.menu__item:hover:before,
.menu__list>.menu__item:active:before,
.menu__list>.menu__item.actived:before {
    width: 100%;
    opacity: 1;
}

.menu__list>.menu__item>.menu__link {
    font-size: 16px;
    font-weight: normal;
    outline: none;
    text-decoration: none;
    color: var(--color-texts);
    user-select: none;
    display: block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
}

.wrapper__menu {
    position: fixed;
    right: 15px;
    z-index: 9999;
}

.btn__hamburger {
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    outline: none;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    transition: all 380ms ease;
}

.btn__hamburger:hover,
.btn__hamburger:active {
    box-shadow: 0 0 25px 6px rgba(55, 85, 124, 0.6);
    background: var(--color-white);
}

.btn__hamburger.actived span {
    transform: rotate(135deg);
}

.btn__hamburger.actived span::before {
    transform: translate(0px, 10px) rotate(-90deg);
    width: 100%;
    opacity: 0;
}

.btn__hamburger.actived span::after {
    transform: translate(0px, -10px) rotate(90deg);
    width: 100%;
    opacity: 1;
}

.btn__hamburger span,
.btn__hamburger span::before,
.btn__hamburger span::after {
    position: absolute;
    height: 4px;
    background: var(--color-secondary);
    border-radius: 4px;
    margin: auto;
}

.btn__hamburger span {
    top: calc(50% - 2px);
    transition: transform 0.3s;
}

.btn__hamburger span::before,
.btn__hamburger span::after {
    content: ' ';
    left: 0;
    transition: transform 0.4s, width 0.4s, opacity 0.1s;
}

.btn__hamburger span::before {
    bottom: 10px;
}

.btn__hamburger span::after {
    top: 10px;
}

.container__hero {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
}

.link__banner{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.6rem;
    padding-top: 0;
    height: 5rem;
}

.link__banner__button{
    height: 50px;
    background-color: var(--color-btn-gray);
    color: var(--color-white);
    letter-spacing: 1.5px;
    font-weight: 300;
}


.hero__main {
    background-color: var(--color-hero-bg);
    background-image: url('../img/bg-main.png');
}

.hero__inner {
    width: 100%;
    height: 100vh;
    display: flex;
}

.inner__column {
    flex: 1;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.hero__texts.main {
    letter-spacing: 4px;
}

.hero__texts.main.left {
    color: var(--color-white);
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
}

.hero__texts.main.right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
}

.hero__texts.left>h3 {
    font-weight: 300;
}

.hero__texts.left>h2 {
    font-weight: bolder;
}

.hero__texts.right>h2 {
    font-weight: 300;
    padding-top: 100px;
}

.btn__demo {
    background-color: #6F6F6F;
    color: var(--color-white);
    letter-spacing: 1.5px;
    font-weight: 300;
    height: 50px;
}

.btn__demo:hover,
.btn__demo:active {
    background-color: #4b4b4b;
}

.section__description {
    background-color: var(--color-white);
    background-image: url('../img/bg-bridge.png'), linear-gradient(var(--color-gradient-section-top), var(--color-gradient-section-bottom));
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.text__goal {
    padding: 10px;
    font-weight: 300;
}

.divider__line {
    margin: 15px auto;
    background-image: url('../img/line-red.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 3px;
}

.divider__line.space{
    margin: 45px auto;
} 

.container__media {
    display: flex;
}

.iframe__container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
}

.iframe__container iframe,
.iframe__container object,
.iframe__container embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.container__texts {
    background-color: #FFFFFF65;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.container__texts>h3 {
    text-align: center;
    width: 80%;
}

.container__texts>p {
    font-weight: 300;
    text-align: center;
}

.section__clients {
    background-image: linear-gradient(var(--color-white) 50%, var(--color-gradient-section-bottom) 100%);
}

.title__clients {
    position: relative;
}

.title__clients:before {
    position: absolute;
    width: 75%;
    height: 3.5px;
    left: 0;
    bottom: -15px;
    background: linear-gradient(to right, var(--color-gradient-line-left), var(--color-gradient-line-right));
}

.container__customers,
.container__statistic {
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 20px 45px;
}

.container__customers>.brand {
    margin: 15px 20px;
    object-fit: contain;
}

.swiper-container {
    height: 250px;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    z-index: 0 !important;
}

.swiper-slide {
    display: flex;
    height: 100%;
    width: 100%;
}

.swiper-slide img {
    object-fit: none;
    width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    outline: none;
    border: 0;
    color: var(--color-gradient-line-right) !important;
}

.container__statistic>.statistic__item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background: var(--color-white);
    transform-origin: center;
    transform: scale(1);
    transition: transform 380ms linear, box-shadow 135ms cubic-bezier(0.4, 0, 0.2, 1);
}

.container__statistic>.statistic__item:hover,
.container__statistic>.statistic__item:active {
    transform: scale(1.1);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.container__statistic>.statistic__item>* {
    letter-spacing: 1.25px;
}

.container__statistic>.statistic__item>h3 {
    margin-top: 0;
    margin: 5px;
    font-size: 38px;
}

.container__statistic>.statistic__item>small {
    font-size: 18px;
    font-weight: 300;
}

.container__usgs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-white);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.container__usgs>.description {
    font-weight: 500;
}

.container__usgs>.logo__usgs {
    height: auto;
}

.container__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 50px;
    background-image: url('../img/bg-footer.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.container__footer>small {
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: var(--color-footer-text);
    margin: 2.5px;
}

.container__footer>small>a {
    outline: none;
    border: 0;
    text-decoration: none;
    color: inherit;
}

.container__footer>.row__contact,
.container__footer>.row__social {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.container__footer>.row__social {
    margin-bottom: -10px;
}

.container__footer>.row__social>.link {
    text-decoration: none;
    outline: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    color: inherit;
}

.container__footer>.row__social>.link>.icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 5px;
    box-shadow: none;
    transform-origin: center;
    transform: scale(1);
    background-color: transparent;
    transition: all 380ms ease;
}

.container__footer>.row__social>.link>.icon:hover {
    transform: scale(1.1);
    background-color: #FFFFFF85;
    box-shadow: 0 5px 15px rgba(250, 0, 0, 0.19), 0 6px 6px rgba(250, 0, 0, 0.23);
}

.container__footer>.row__contact>* {
    margin: 2px 5px;
    font-size: 16px;
}

.container__footer>.copyright {
    margin-top: 15px;
    margin-bottom: -15px;
    font-size: 16px;
}


/* About us*/
.hero__about {
    background-color: var(--color-white);
    background-image: url('../img/bg-circle.png');
    background-position-y: 76px;
    background-attachment: unset;
}

.hero__texts.about {
    letter-spacing: 2px;
}

.hero__texts.about.left {
    color: var(--color-black);
}

.hero__texts.about.left>h2 {
    font-weight: bold;
}

.hero__texts.about.left>p {
    font-weight: 300;
}

.hero__texts.about.left,
.hero__texts.about.right {
    display: flex;
    flex-direction: column;
}

.hero__texts.about.right>img {
    margin: 30px 0;
}

.btn__solutions {
    color: var(--color-white);
    font-size: 23px;
    font-weight: 300;
    height: 50px;
    background-color: #BF072B;
}

.btn__solutions:hover,
.btn__solutions:active {
    background-color: #970824;
}

.section__collaboration {
    background-color: var(--color-white);
    background-image: url('../img/bg-bridge-white.png');
    background-size: cover;
    background-position: center 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.text__collaborates {
    padding: 10px;
    font-weight: 300;
}

.section__awards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    padding: 20px 0;
}

.title__awards {
    font-weight: bolder;
}

.container__awards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: stretch;
    padding: 20px 45px;
}

.container__awards>.award {
    margin: 30px 75px;
    object-fit: contain;
}

.section__press {
    background-color: var(--color-white);
    background-image: url('../img/bg-duo-circle.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.title__press {
    font-weight: bold;
}

.iframe__press {
    position: relative;
    width: 40%;
    padding-bottom: 23.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: auto;
}

.iframe__press iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.container__videos{
    padding: 15px;
}

.container__videos .video{
    width: 448px;
    margin: 15px auto;
    display: flex;
    flex-flow: column nowrap;
}

.container__videos iframe{
    height: 400px;
}

.container__videos .video > .video__title{
    font-weight: bold;
    border-bottom: 3px solid var(--color-primary);
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.container__videos .video > .video__subheader{
    margin-top: 5px;
    font-weight: 300;
}

.container__press, 
.container__videos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.container__press>a {
    text-decoration: none;
    outline: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    color: inherit;
}

.note {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--color-white);
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #7b7b7b29;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: all 380ms cubic-bezier(.25, .8, .25, 1);
}

.note:hover,
.note:active {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transform: scale(1.05);
}

.header__note {
    overflow: hidden;
    filter: brightness(55%);
    transition: filter 500ms cubic-bezier(0.43, 0.41, 0.22, 0.91);
}

.note:hover>.header__note {
    filter: brightness(100%);
}

.header__note>img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    user-select: none;
}

.body__note:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, var(--color-gradient-line-left), var(--color-gradient-line-right));
}

.body__note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    padding: 10px;
    position: relative;
}

.body__note>p {
    margin: 5px;
    line-height: 24px;
    position: relative;
}

.body__note>p:before {
    content: ' ';
    position: absolute;
    top: 21px;
    left: -2px;
    height: 3px;
    width: 68px;
    background: var(--color-primary);
}

/* Solutions */
.hero__solutions {
    background-color: var(--color-hero-bg);
    background-image: url('../img/bg-crack.png');
}

.title__solutions {
    font-weight: bolder;
}

.container__solutions {
    display: flex;
    align-items: stretch;
}

.item>p {
    text-align: center;
    width: 85%;
    font-size: 22px;
    font-weight: 300;
    margin: 2px 0;
}

.item>p>strong {
    color: var(--color-primary);
    font-size: inherit;
}

.circle__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-image: url('../img/solutions/circle.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.circle__item:before,
.circle__item:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
}


.circle__item:after {
    transform: translate(-50%, -50%) scale(1);
    animation: wave-border-blue 1.75s ease-in-out infinite;
}

.circle__item>img {
    height: auto;
    object-fit: contain;
    margin-top: -10px;
}

.circle__item.epicenter>img {
    width: 200px;
}

.section__form {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.section__form.solutions {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.section__form.solutions {
    background-color: var(--color-white);
    background-image: url('../img/bg-bridge-2.png');
    background-attachment: fixed;
}

.text__solutions {
    font-weight: 300;
}

.section__system {
    background-image: url('../img/bg-circle-blue.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.title__system {
    color: var(--color-primary);
}

.list__features {
    list-style: none;
    margin-left: -8px;
    padding: 0;
}

.list__features>li {
    background: url('../img/bullet.png') no-repeat 8px 6px;
    background-size: 20px;
    padding-left: 38px;
    padding-top: 4px;
    font-weight: 300;
}


.container__system {
    margin-top: 50px;
    display: flex;
    align-items: stretch;
}

.feature {
    margin: 15px;
}

.img__feature {
    width: 125px;
    height: 125px;
    position: relative;
}

.img__feature:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #F32A4B10;
    border: 1px solid #F32A4B25;
    animation: wave-red 1.5s linear infinite;
}

.img__feature>img {
    width: 125px;
    height: auto;
    object-fit: contain;
}

.feature>small {
    margin-top: 15px;
    font-size: 16px;
    text-align: center;
}

/* How it works */
.hero__works {
    background-image: url('../img/bg-crack-2.png');
    background-position: bottom right;
}

.title__works {
    font-weight: bolder;
}

.container__process {
    display: flex;
    align-items: stretch;
}

.process>h3 {
    text-align: center;
}

.process>p {
    text-align: center;
    font-weight: 300;
    width: 85%;
}

.section__eews {
    background-color: var(--color-white);
    background-image: url('../img/bg-circle.png');
    background-size: cover;
    background-position: center 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.title__eews {
    font-weight: bolder;
    margin-bottom: 25px;
    color: #f32A4B;
}

.container__eews {
    display: flex;
    justify-content: center;
    align-items: stretch;
    transition: all 380ms ease;
}

.container__eews>.side__left,
.container__eews>.side__right {
    display: flex;
    flex-flow: column nowrap;
}


.container__eews>.side__left {
    justify-content: space-evenly;
    padding-right: 35px;
}

.container__eews>.side__right {
    align-items: center;
    justify-content: center;

}

.container__eews>.side__left>p {
    font-weight: 300;
}

.container__eews>.side__right>.img__device {
    position: relative;
}

.container__eews>.side__right>.img__device:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    animation: wave-border-red 1.5s linear infinite;
}


.container__eews>.side__right>.img__device>img {
    height: auto;
    object-fit: contain;
    animation: zoom-in-out 5s ease-in-out infinite alternate;
}

/* Contact */
.hero__contact {
    background-image: url('../img/bg-bridge-blue.png');
    background-color: var(--color-secondary);
    background-attachment: fixed;
}

.title__contact {
    font-weight: bolder;
    margin-bottom: 25px;
}

.section__form.contact {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
}

.section__form.contact>.side__left.contact {
    padding-top: 25px;
}

.section__form.contact>.side__right.contact {
    padding-top: 50px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}

.section__form.contact>.side__right.contact>form-contact {
    flex: 1;
}

.list__contact {
    list-style: none;
    margin-left: -8px;
    padding: 0;
}

.list__contact>li {
    background: url('../img/bullet.png') no-repeat 8px 16px;
    background-size: 20px;
    padding-left: 38px;
    padding-top: 4px;
    font-size: 36px;
    color: var(--color-secondary);
    margin: 35px 0;
}

/* Expo California */
.hero__expo{
    background-image: url('../img/bg-main.png');
    background-color: var(--color-secondary);
    background-attachment: fixed;
}


/* Animations */
@keyframes toUp {
    to {
        transform: translateY(15px);
        opacity: 0;
    }

    from {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes wave-border-blue {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1);
        box-shadow: inset 0 0 0 transparent, 0 0 0 0 #8698AB;
    }

    50% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1);
        box-shadow: inset 0 0 0 transparent, 0 0 0 10px #8698AB;
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
        box-shadow: inset 0 0 0 transparent, 0 0 0 10px #8698AB;
    }
}

@keyframes wave-red {
    0% {
        opacity: 0;
        transform: scale(1);
    }

    75% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        opacity: 0;
        transform: scale(1.2);
    }
}

@keyframes wave-border-red {
    0% {
        opacity: 0;
        transform: scale(1);
        box-shadow: inset 0 0 0 transparent, 0 0 0 0 #f32A4B25;
    }

    50% {
        opacity: 1;
        transform: scale(1.1);
        box-shadow: inset 0 0 0 transparent, 0 0 0 10px #f32A4B55;
    }

    100% {
        opacity: 0;
        transform: scale(1.2);
        box-shadow: inset 0 0 0 transparent, 0 0 0 10px #f32A4B35;
    }
}

@keyframes zoom-in-out {
    from {
        transform: scale(0.95);
    }

    to {
        transform: scale(1);
    }
}

@keyframes pulse {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes background-change {
    from {
        background-color: #2b3952;
    }

    to {
        background-color: #536280;
    }
}

/* MEDIA QUERIES*/
@media only screen and (max-width: 319px) {
    .swiper-pagination {
        display: none !important;
    }
    .link__banner__button{
        font-size: 1rem;
        width: 80%;
        margin: auto;
    }
}

@media only screen and (min-width: 320px) {

    /* Preloader */
    .preloader.active .circles .pulse.circle__1 {
        width: 300px;
        height: 300px;
    }

    .preloader.active .circles .pulse.circle__2 {
        width: 250px;
        height: 250px;
    }

    .preloader.active .circles .pulse.circle__3 {
        width: 200px;
        height: 200px;
    }

    .preloader .circles .logo {
        width: 150px;
        height: 150px;
    }

    /* Navbar */
    .menu__list {
        display: none;
    }

    .img__logo {
        width: 90px;
        margin-bottom: -1px;
    }

    .wrapper__menu {
        display: block;
    }

    .btn__hamburger {
        width: 50px;
        height: 50px;
    }

    .btn__hamburger span,
    .btn__hamburger span::before,
    .btn__hamburger span::after {
        width: 32px;
    }

    .btn__hamburger span {
        left: calc(50% - 16px);
    }

    .container__hero {
        background-position: center;
        background-attachment: scroll;
    }

    .container__footer {
        background-position: left center;
    }

    .hero__inner,
    .section__description,
    .section__clients,
    .section__collaboration,
    .section__press,
    .section__form,
    .section__system,
    .section__eews {
        padding: 20px;
    }

    .hero__inner {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    /* Home */
    .link__banner__button{
        font-size: 1rem ;
        width: 80%;
        margin: auto;
    }

    .isotype {
        background: none;
        background-size: unset;
        background-position: unset;
        background-repeat: unset;
    }

    .hero__texts.left>h3 {
        font-size: 18px;
        text-align: center;
    }

    .hero__texts.left>h2 {
        text-align: center;
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2 {
        font-size: 24px;
    }

    .hero__texts.main.right,
    .hero__texts.main.left {
        width: 80%;
    }

    .hero__texts.main.left {
        padding-top: 50px;
    }

    .hero__texts.main.right {
        margin-top: -125px;
    }

    .btn__demo {
        margin-top: unset;
        width: 250px;
        font-size: 20px;
    }

    .section__description,
    .section__collaboration,
    .section__form {
        height: 100%;
    }

    .text__goal,
    .text__collaborates,
    .text__solutions {
        font-size: 20px;
        letter-spacing: 0.95px;
    }

    .divider__line {
        width: 95%;
    }

    .container__media {
        margin-top: 30px;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    .iframe__container,
    .container__texts {
        flex: .50;
    }

    .iframe__container {
        width: 95%;
        margin: auto;
    }

    .container__texts {
        margin-top: 30px;
        justify-content: center;
        align-items: center;
        margin-right: unset;
    }

    .container__texts>h3 {
        font-size: 22px;
    }

    .container__texts>p {
        margin-top: unset;
        font-size: 24px;
    }

    .title__clients,
    .title__awards,
    .title__press,
    .hero__texts.about.left>h2,
    .title__solutions,
    .title__system,
    .title__works,
    .title__eews,
    .title__contact {
        font-size: 30px;
    }

    .title__clients {
        text-align: center;
        margin: 45px auto;
        padding-left: 0;
        width: auto;
    }

    .title__clients:before {
        content: unset;
    }

    .container__customers {
        display: none;
    }

    .swiper-container {
        width: 100%;
        display: block;
        margin-bottom: 20px;
    }

    .swiper-pagination {
        display: none !important;
    }

    .container__usgs,
    .container__statistic,
    .section__form.solutions,
    .container__system,
    .container__process,
    .container__eews,
    .section__form.contact {
        flex-flow: column nowrap;
    }

    .container__statistic>.statistic__item {
        height: 160px;
        width: 100%;
        margin: 25px auto;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .container__usgs {
        padding: 25px 20px;
        margin: 30px auto;
    }

    .container__usgs>.description,
    .container__usgs>.logo__usgs {
        flex: 0.5;
    }

    .container__usgs>.description {
        font-size: 22px;
        padding-left: 0;
        text-align: center;
        letter-spacing: 0.55px;
    }

    .container__usgs>.logo__usgs {
        width: 200px;
        margin-left: unset;
        object-fit: contain;
    }

    /* About us */
    .hero__texts.about {
        letter-spacing: 0.95px;
    }

    .hero__texts.about.left {
        justify-content: center;
        width: 100%;
    }

    .hero__texts.about.left>h2 {
        margin-bottom: 25px;
    }

    .hero__texts.about.left>p {
        font-size: 22px;
        text-align: center;
        font-weight: 300;
    }

    .btn__solutions {
        width: 250px;
        margin: 25px auto;
        font-size: 18px;
        letter-spacing: 1.1px;
    }

    .hero__texts.about.right {
        display: none;
    }

    .text__collaborates {
        width: 100%;
        margin: auto;
    }

    .title__awards,
    .title__press,
    .title__solutions,
    .title__system,
    .title__works,
    .title__eews,
    .title__contact {
        text-align: center;
    }

    .container__awards>.award {
        margin: 20px;
        object-fit: unset;
        width: 200px;
        height: auto;
    }

    .title__press {
        margin-bottom: 50px;
    }

    .container__press {
        padding: 0;
        margin-top: 50px;
    }

    .note {
        max-width: 95%;
        width: 95%;
        margin: 15px auto;
    }

    /*Solutions*/
    .title__solutions,
    .title__works {
        margin-top: 80px;
        margin-bottom: 20px;
    }

    .container__solutions,
    .container__process {
        overflow: hidden auto;
        margin: auto -20px;
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    .item:nth-child(1) {
        padding-top: 15px;
    }

    .item {
        margin: 15px 20px;
        display: block;
    }

    .item>p,
    .item>ul>li {
        width: 85%;
        font-size: 18px;
        margin: auto;
    }

    .item>ul>li {
        font-weight: 300;
    }

    .circle__item {
        width: 125px;
        height: 125px;
        margin: auto;
        margin-bottom: 15px;
    }

    .circle__item:before,
    .circle__item:after {
        width: 140px;
        height: 140px;
    }

    .circle__item>img {
        width: 90px;
    }

    .section__form>.side__left.solutions,
    .section__form>.side__right.solution {
        flex: 50%;
    }

    .section__form>.side__left.solutions {
        padding-right: unset;
    }

    .text__solutions:not(:first-of-type) {
        display: none;
    }

    .feature {
        display: block;
        margin: 20px auto;
    }

    .img__feature {
        margin: auto;
    }

    .list__features>li {
        font-size: 20px;
        width: 100%;
        margin: 20px auto;
    }

    .container__system,
    .container__process {
        justify-content: flex-start;
        height: 530px;
        overflow: hidden auto;
    }

    /* How it works */
    .container__process {
        height: unset;
    }

    .process {
        margin: 50px 0;
        display: block;
    }

    .process:nth-child(1) {
        padding-top: 35px;
    }

    .process>h3 {
        font-size: 24px;
        text-align: center;
    }

    .process>p {
        font-size: 18px;
        margin: 15px auto;
    }

    .process>img {
        width: 100%;
        display: block;
        margin: auto;

    }

    .container__eews {
        flex-direction: column-reverse;
    }

    .container__eews>.side__left {
        padding-right: unset;
        align-items: center;
    }

    .container__eews>.side__left>p {
        font-size: 18px;
        line-height: unset;
    }

    .container__eews>.side__right>.img__device {
        margin: 50px auto;
    }

    .container__eews>.side__right>.img__device>img {
        width: 255px;
    }

    /* Contact */
    .hero__contact {
        height: 100%;
    }

    .list__contact {
        display: none;
    }

    .section__form.contact {
        width: 95%;
    }

    .section__form.contact>.side__right.contact {
        margin: 0 -5px;
        padding-top: 25px;
    }

    /* Expo California */
    .hero__expo {
        height: 100%;
    }

    /* Keyframes */
    @keyframes wave-border-blue {
        0% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1);
            box-shadow: inset 0 0 0 transparent, 0 0 0 0 #8698AB;
        }

        50% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.1);
            box-shadow: inset 0 0 0 transparent, 0 0 0 3px #8698AB;
        }

        100% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1.2);
            box-shadow: inset 0 0 0 transparent, 0 0 0 3px #8698AB;
        }
    }

}

@media only screen and (min-width: 350px) {

    .swiper-pagination {
        display: block !important;
    }

    .container__videos iframe{
        height: 300px;
    }

    .main__banner img{
        height: auto;
    }
}

@media only screen and (min-width: 375px) {

    /* Home */
    .hero__texts.left>h3 {
        font-size: 22px;
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2 {
        font-size: 30px;
    }

    .btn__demo {
        margin-top: -15px;
    }

    .container__awards>.award {
        margin: 30px;
        object-fit: contain;
        width: unset;
        height: unset;
    }

    .text__solutions:not(:first-of-type) {
        display: block;
        margin: 20px auto;
    }

    .text__solutions:last-child {
        display: none;
    }

    .container__videos iframe{
        height: 350px;
    }

}

@media only screen and (min-width: 480px) {

    /* Home */
    .img__logo {
        width: 98px;
    }

    .btn__hamburger {
        width: 60px;
        height: 60px;
    }

    .btn__hamburger span,
    .btn__hamburger span::before,
    .btn__hamburger span::after {
        width: 36px;
    }

    .btn__hamburger span {
        left: calc(50% - 18px);
    }

    .hero__texts.left>h3 {
        font-size: 25px;
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2 {
        font-size: 35px;
    }

    .container__statistic>.statistic__item {
        width: 85%;
    }

    .btn__demo {
        margin-top: unset;
    }

    .container__usgs>.description {
        font-size: 28px;
        letter-spacing: 1px;
    }

    /* About us*/
    .hero__texts.about.left>h2 {
        margin-bottom: 5px;
    }

    .hero__texts.about.right {
        display: flex;
    }

    .btn__solutions {
        margin: auto;
    }

    .hero__texts.about.right>img {
        width: 185px;
        margin-bottom: -25px;
    }

    .container__videos iframe{
        height: 380px;
    }

    /*Solutions*/
    .item {
        margin: 20px;
    }

    .item>p,
    .item>ul>li {
        font-size: 20px;
        width: 75%;
    }

    .circle__item {
        margin-bottom: 20px;
    }

    .text__solutions:nth-child(2) {
        margin: auto;
    }

    .text__solutions:last-child {
        display: block;
    }
}

@media only screen and (min-width: 600px) {

    /* Preloader */
    .preloader.active .circles .pulse.circle__1 {
        width: 350px;
        height: 350px;
    }

    .preloader.active .circles .pulse.circle__2 {
        width: 300px;
        height: 300px;
    }

    .preloader.active .circles .pulse.circle__3 {
        width: 250px;
        height: 250px;
    }

    .preloader .circles .logo {
        width: 200px;
        height: 200px;
    }

    .img__logo {
        width: 100px;
        margin-bottom: unset;
    }

    .swiper-container {
        width: 75%;
        display: block;
        margin: auto;
    }

    .iframe__container {
        width: 85%;
    }

    .container__usgs>.logo__usgs {
        width: 250px;
    }

    .container__usgs>.description {
        font-size: 32px;
    }

    /*About us*/
    .hero__texts.about.left>h2 {
        margin-bottom: auto;
    }

    .hero__texts.about.left>p {
        margin: 30px auto;
    }

    .btn__solutions {
        margin: auto;
    }

    .hero__texts.about.right>img {
        width: 275px;
        margin-bottom: auto;
    }

    .note {
        max-width: 85%;
        width: 85%;
    }

    /* solutions */
    .section__form>.side__right.solutions {
        margin: auto;
        padding-top: 25px;
    }

    .list__features>li {
        font-size: 22px;
        width: 85%;
        margin: 20px auto 20px 0;
    }

    .container__system {
        justify-content: center;
        flex-flow: row wrap;
        height: unset;
        overflow: unset;
    }

    .container__eews>.side__left>p {
        width: 100%;
        text-align: center;
        line-height: 20px;
    }


    /* How it works */
    .process>img {
        width: 70%;
    }

    /* Contact */
    .section__form.contact {
        width: 500px;
    }
}

@media only screen and (min-width: 768px) {
    .link__banner__button{
        font-size: 1.25rem;
        width: 85%;
    }

    /*About us*/
    .hero__texts.about.right>img {
        width: 350px;
        margin-bottom: auto;
    }

    .note {
        max-width: 625px;
        width: unset;
    }

    .container__solutions {
        flex-flow: row wrap;
        justify-content: center;
        padding: 0 100px;
        max-width: 100%;
    }

    .item:nth-child(1) {
        padding-top: unset;
    }

    /*Solutions*/
    .feature {
        margin: 20px 25px;
    }

    .container__eews>.side__right>.img__device>img {
        width: 335px;
    }
}

@media only screen and (min-width: 900px) {

    /* Preloader */
    .preloader.active .circles .pulse.circle__1 {
        width: 400px;
        height: 400px;
    }

    .preloader.active .circles .pulse.circle__2 {
        width: 350px;
        height: 350px;
    }

    .preloader.active .circles .pulse.circle__3 {
        width: 300px;
        height: 300px;
        opacity: 0.65;
    }

    .preloader .circles .logo {
        width: 250px;
        height: 250px;
    }

    /* Navbar */
    .menu__list {
        display: flex;
    }

    .wrapper__menu {
        display: none;
    }

    .img__logo {
        width: 105px;
    }

    .container__hero {
        background-position: center;
    }

    /* Home */
    .isotype {
        background: url('../img/isotype.png');
        background-position: center 75px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .hero__inner,
    .section__description,
    .section__clients,
    .section__collaboration,
    .section__press,
    .section__form {
        padding: 25px;
    }

    .hero__inner {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .hero__texts.main.left {
        padding-top: 100px;
        width: calc(50% - 50px);
    }

    .hero__texts.left>h3 {
        font-size: 35px;
        text-align: left;
    }

    .hero__texts.left>h2 {
        text-align: left;
    }

    .hero__texts.main.right {
        width: calc(50% - 150px);
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2,
    .hero__texts.about.left>h2,
    .title__clients,
    .title__awards,
    .title__press,
    .title__solutions,
    .title__system,
    .title__works,
    .title__eews,
    .title__contact {
        font-size: 40px;
    }

    .hero__texts.main.right {
        margin-top: unset;
    }

    .btn__demo {
        width: 250px;
        font-size: 21px;
    }

    .section__description,
    .section__collaboration {
        height: 100vh;
    }

    .text__goal,
    .text__collaborates,
    .text__solutions {
        text-align: center;
        font-size: 26px;
        letter-spacing: 1.5px;
    }

    .divider__line {
        width: 75%;
    }

    .container__media {
        margin-top: 50px;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;
    }

    .iframe__container,
    .container__texts {
        flex: .48;
    }

    .iframe__container {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 0;
        height: 345px;
    }

    .container__texts {
        margin-top: unset;
        justify-content: space-evenly;
        margin-right: -25px;
    }

    .container__texts>h3 {
        font-size: 28px;
    }

    .container__texts>p {
        margin-top: 0;
        font-size: 22px;
    }

    .title__clients {
        text-align: left;
        margin: 45px 0 45px -25px;
        padding-left: 25px;
        width: 375px;
    }

    .title__clients:before {
        content: ' ';
    }

    .container__customers {
        display: flex;
    }

    .swiper-container {
        display: none;
    }

    .container__usgs>.description {
        font-size: 22px;
        padding-left: 0;
        text-align: center;
        letter-spacing: 0.55px;
    }

    .container__usgs>.logo__usgs {
        width: 200px;
        margin-left: unset;
    }

    .container__usgs,
    .container__statistic,
    .container__customers,
    .section__form.solutions,
    .container__process {
        flex-flow: row wrap;
    }

    .container__statistic>.statistic__item {
        width: 300px;
        height: 160px;
        margin: 25px 35px;
        box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16);
    }

    .container__usgs {
        margin: 32px -25px 0 -25px;
    }

    .container__usgs>.description {
        flex: 0.70;
    }

    .container__usgs>.logo__usgs {
        flex: 0.30;
    }

    .container__usgs>.description {
        font-size: 28px;
        padding-left: 15px;
        text-align: left;
        letter-spacing: 1.43px;
    }

    .container__usgs>.logo__usgs {
        width: 200px;
        margin-left: 30px;
        object-fit: none;
    }

    /* About us */
    .hero__texts.about {
        letter-spacing: 2;
    }

    .hero__texts.about.left {
        padding-top: 25px;
        justify-content: space-around;
        width: calc(50% - 15px);
    }

    .hero__texts.about.left>h2 {
        margin-bottom: 0;
    }

    .hero__texts.about.left>p {
        font-size: 26px;
        text-align: left;
    }

    .hero__texts.about.right {
        display: flex;
    }

    .hero__texts.about.right>img {
        width: 450px;
        margin: auto;
    }

    .btn__solutions {
        width: 300px;
        letter-spacing: 1.5px;
        margin: 0 auto;
        font-size: 21px;
    }

    .text__collaborates {
        width: 85%;
        margin: 25px auto auto;
    }

    .title__awards,
    .title__press,
    .title__solutions,
    .title__system,
    .title__works,
    .title__eews {
        text-align: left;
    }

    .container__press {
        max-width: unset;
        padding: 15px;
        margin-top: 25px;
    }

    .note {
        max-width: 400px;
        margin: 15px auto;
    }

    .iframe__container.press {
        width: 50%;
    }

    /* solutions */
    .hero__solutions {
        height: 100%;
    }

    .inner__column {
        flex-flow: column nowrap !important;
    }

    .container__solutions {
        max-width: 85%;
        padding: 0;
    }

    .title__solutions {
        letter-spacing: 1.2px;
        margin-top: 80px;
        margin-bottom: auto;
    }

    .container__solutions,
    .container__process {
        overflow: unset;
        margin: auto;
        justify-content: center;
    }

    .item,
    .feature,
    .process {
        margin: 15px 35px;
        flex: 1;
        flex-flow: column nowrap;
        align-items: center;
    }

    .item>p,
    .item>ul>li {
        font-size: 22px;
    }

    .item>ul>li {
        width: 100%;
    }

    .circle__item {
        width: 185px;
        height: 185px;
        margin-bottom: 25px;
    }

    .circle__item:before,
    .circle__item:after {
        width: 200px;
        height: 200px;
    }

    .circle__item>img {
        width: 130px;
    }

    .section__form.solutions {
        flex-wrap: nowrap;
    }

    .text__solutions {
        text-align: justify;
    }

    .section__form>.side__left.solutions {
        padding-right: 44px;
    }

    .list__features>li {
        width: 545px;
    }

    .feature,
    .process {
        display: flex;
    }

    .process>img {
        width: 100%;
        display: unset;
        margin: 0;
    }

    /* How it works */
    .container__process {
        margin: auto;
    }

    .process:nth-child(1) {
        padding-top: unset;
    }

    .container__eews {
        flex-flow: row nowrap;
    }

    .container__eews>.side__left {
        flex: 60%;
        padding-right: 75px;
        line-height: 30px;
        align-items: unset;
    }

    .container__eews>.side__right {
        flex: 40%;
    }

    .container__eews>.side__left>p {
        text-align: justify;
        font-weight: 300;
        line-height: 40px;
    }

    /* Contact */
    .list__contact {
        display: block;
    }

    .section__form.contact {
        flex-flow: row nowrap;
        width: unset;
    }

    .section__form.contact>.side__left.contact {
        flex: 50%;
    }

    .section__form.contact>.side__right.contact {
        flex: 50%;
        margin-top: 80px;
    }

    .list__contact>li {
        font-size: 28px;
    }


    /*keyframes*/
    @keyframes wave-border-blue {
        0% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1);
            box-shadow: inset 0 0 0 transparent, 0 0 0 0 #8698AB;
        }

        50% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.1);
            box-shadow: inset 0 0 0 transparent, 0 0 0 10px #8698AB;
        }

        100% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1.2);
            box-shadow: inset 0 0 0 transparent, 0 0 0 10px #8698AB;
        }
    }

    .link__banner__button{
        font-size: 1rem;
    }
}

@media only screen and (min-width: 1024px) {
    .link__banner__button{
        font-size: 1.5rem ;
        width: auto !important;
    }

    /* Home */
    .hero__texts.main {
        line-height: 48px;
    }

    .hero__texts.left>h3 {
        font-size: 35px;
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2 {
        font-size: 42px;
    }

    .container__media {
        margin-top: 2rem;
    }

    .container__texts>h3 {
        font-size: 30px;
    }

    .container__texts>p {
        margin-top: unset;
        font-size: 26px;
    }

    .container__statistic>.statistic__item {
        margin: 15px 25px;
        width: 240px;
        height: 126px;
    }

    /* About us */
    .note {
        max-width: 450px;
        margin: 25px 20px;
    }

    /* solutions */
    .text__solutions,
    .title__system {
        text-align: left;
    }

    .section__form>.side__left.solutions {
        flex: 60%;
        padding-right: 12.5%;
    }

    .section__form>.side__right.solutions {
        flex: 40%;
    }

    /* How it works */
    .process {
        margin: 15px;
    }

    .process>img {
        width: 85%;
    }

    .process>h3 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .process>p {
        font-size: 19px;
    }

    .container__eews>.side__left>p {
        font-size: 24px;
        width: 88%;
    }

    .link__banner__button{
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1200px) {

    /* Home */
    .hero__inner,
    .section__description,
    .section__clients,
    .section__collaboration,
    .section__press,
    .section__form,
    .section__system,
    .section__eews {
        padding: 35px;
    }

    .hero__texts.main {
        line-height: 52px;
    }

    .hero__texts.left>h3 {
        font-size: 38px;
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2 {
        font-size: 45px;
    }

    .container__texts {
        margin-right: -35px;
    }

    .hero__texts.main.right {
        width: calc(50% - 300px);
    }

    .container__media {
        margin-top: 3rem;
    }

    .title__clients,
    .hero__texts.about.left>h2,
    .title__awards,
    .title__press,
    .title__system,
    .title__works,
    .title__contact {
        font-size: 50px;
    }

    .title__clients {
        margin: 50px 0 50px -35px;
        padding-left: 35;
        width: 500px;
    }

    .container__usgs {
        padding: 25px 35px;
        margin: 32px -35px 0 -35px;
    }

    .container__usgs>.description {
        padding-left: 30px;
    }

    .container__usgs>.description {
        font-size: 30px;
    }

    /*About us*/
    .hero__texts.about {
        letter-spacing: 2px;
    }

    .hero__texts.about.left>h2 {
        margin-bottom: -45px;
    }

    .hero__texts.about.left>p {
        font-size: 28px;
    }

    .hero__texts.about.left {
        padding-top: unset;
        justify-content: space-around;
        width: calc(50% - 60px);
    }

    .btn__solutions {
        font-size: 23px;
    }

    .hero__texts.about.right>img {
        width: 80%;
        margin: auto 0 auto auto;
    }

    .container__awards>.award {
        margin: 30px 75px;
    }

    /* solutions */
    .hero__solutions {
        height: 100vh;
    }

    .container__solutions {
        max-width: unset;
    }

    .item,
    .process,
    .feature {
        margin: 15px;
    }

    .container__eews>.side__left>p {
        font-size: 26px;
    }

    .container__eews>.side__right>.img__device {
        margin: auto;
    }

    .container__eews>.side__right>.img__device>img {
        width: 450px;
    }
    .link__banner__button{
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1400px) {

    /* Home */
    .hero__inner,
    .section__description,
    .section__clients,
    .section__collaboration,
    .section__press,
    .section__form,
    .section__system,
    .section__eews {
        padding: 50px;
    }

    .hero__texts.main {
        line-height: 58px;
    }

    .hero__texts.main.left {
        width: calc(50% - 100px);
    }

    .hero__texts.left>h3 {
        font-size: 40px;
    }

    .hero__texts.left>h2,
    .hero__texts.right>h2 {
        font-size: 50px;
    }

    .container__texts {
        margin-right: -50px;
    }

    .title__clients {
        margin: 65px 0 65px -50px;
        padding-left: 50px;
    }

    .iframe__container {
        height: 500px;
    }

    .container__texts>p {
        margin-top: -30px;
    }

    .container__usgs {
        padding: 25px 50px;
        margin: 32px -50px 0;
    }

    /* About us*/
    .hero__texts.about.right>img {
        width: 100%;
        margin: 30px -50px 30px auto;
    }

    /* solutions */
    .item,
    .feature {
        margin: 20px;
    }

    /* Contact */
    .hero__contact {
        height: 100vh;
    }

    .link__banner__button{
        font-size: 1.5rem;
    }

    /* Expo California */

    .hero__expo {
        height: 100vh;
    }
}

@media only screen and (min-width: 1600px) {
    .main__banner img{
        height: 742px;
    }

    .container__footer {
        background-position: center;
    }

    /* About us*/
    .hero__texts.about.right>img {
        width: auto;
    }

    /* How it works */
    .process {
        margin: 35px auto;
    }

    .process>img {
        width: unset;
    }

    .process>h3 {
        font-size: 36px;
    }

    .process>p {
        font-size: 18px;
    }

}